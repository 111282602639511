<template>
  <div id="access-v">

    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>

    <div class="hidden-xs-only">
      <HomeAccess/>
      <PageFooter/>
    </div>

    <div class="hidden-sm-and-up">
      <m-access/>
      <m-footer/>
    </div>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import HomeAccess from "@/components/home/HomeAccess";
import MTop from "../components/m/MTop";
import MFooter from "../components/m/MFooter";
import MAccess from "../components/m/MAccess";
export default {
  name: "Access",
  components: {MAccess, MFooter, MTop, HomeAccess, PageFooter, PageHeader}
}
</script>

<style scoped>
#access-v {
  /* border-top: 10px solid #1796c4; */
}


#access-v /deep/ .access .access-title {
  font-size: 24px;
  color: #666;
  text-align: left;
  margin-bottom: 70px;
}
</style>
